import { exec } from "child_process";
import React, { useEffect } from "react";

interface Props {}

export const DiscordBot = (p: Props) => {
  let stats: any = {};

  function fetchData(): void {
    fetch(
      process.env.REACT_APP_CURRENT_NEW_WORLD_STATUS_URL! +
        process.env.REACT_APP_CURRENT_NEW_WORLD_SERVER!,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${process.env.REACT_APP_CURRENT_NEW_WORLD_STATUS_API_KEY}`,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      // eslint-disable-next-line react-hooks/exhaustive-deps
      .then((data) => {
        stats = JSON.parse(data);
        document.write(data);
      })
      .catch((e) => {
        console.log(e);
        document.write(e);
      });
  }

  useEffect(() => {
    fetchData();
    setTimeout(
      fetchData,
      Number(process.env.REACT_APP_CURRENT_FETCH_NEW_WORLD_STATS_MS) || 60000
    );
  });

  return <></>;
};
