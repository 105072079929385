import LinkdinLogo from '../images/linkedin.png';

export const Signature = () => {
	return (
		<div className='signature transform mb-10 w-100'>
			<div className='float-left w-100'>
				<strong>
					{' '}
					With Kind Regards,
					<br />
				</strong>
				Stefan van de Laarschot
			</div>
			<a rel='noreferrer' className='float-left w-100 mb-5' href='https://www.linkedin.com/in/stefan-van-de-laarschot-3540b4b4/' target='_blank'>
				<img src={LinkdinLogo} alt='Linkedin' />
			</a>
		</div>
	);
};
