import ProjectsJson from "../data/projects.json";

export const Projects = () => {
  function buildProjects() {
    return ProjectsJson.map((element, index) => {
      return (
        <div key={index} className="list-group mt-3 mb-3 myListGroup">
          <h2>{element.name}</h2>
          {element.projects.map((project, pindex) => {
            return (
              <span
                key={pindex}
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">{project.name}</h5>
                  <small className="text-muted">{project.year}</small>
                </div>
                <p className="mb-1">{project.description}</p>
              </span>
            );
          })}
        </div>
      );
    });
  }

  return (
    <>
      <h1 className="animated pulse">My Projects</h1>
      <p>
        Specific Projects I worked on for my current or previous employer(s):
      </p>
      {buildProjects()}
    </>
  );
};
