export const Contact = () => {
	return (
		<>
			<h1 className='animated pulse'>Contact</h1>
			<p>
				If you want more information about me you can always contact me by using{' '}
				<a rel='noreferrer' href='https://www.linkedin.com/in/stefan-van-de-laarschot-3540b4b4/' target='_blank'>
					My Linkedin profile
				</a>
			</p>
		</>
	);
};
