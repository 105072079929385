import DocumentMeta from "react-document-meta";

export const AboutMe = () => {
  function getAge(dateString: string) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const meta = {
    title: "About Me - Stefan van de Laarschot",
    description:
      "Stefan van de Laarschot is a professional ASP.NET Full Stack Developer.",
    canonical: "https://vandelaarschot.online/AboutMe",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "ASP.NET,Software Developer,Van de Laarschot,Developer",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="body-content animated fadeIn">
        <h1 className="animated pulse">About Me</h1>

        <p>
          I'm Stefan van de Laarschot
          <br />
          I'm {getAge("1988/12/24")} years old
          <br />
          I live in the south of the Netherlands, in the province of Limburg
          <br />
          <br />
          I'm the dad of a lovely son, Jayden. He is {getAge("2015/03/05")}{" "}
          years old :).
          <br />
          I am educated as an ICT Support (MBO) employee, but things went
          differently in my life.
          <br />
          I figured out that my passion is software development. Every single
          school day, I was creating websites with FrontPage 2003 back in the
          day
          <br />
          with tables and iframes. Yes, that's how I started! I tried to get
          better and better. I combined it with Photoshop, and the webpages
          started to look better and better.
          <br />
          <br />I decided to do something with this, so I started to program in
          C#. I still can freshly remember this day! I started an empty project
          in Visual Studio 2010 and began an ASP.NET Web Forms project with
          MasterPages. It was very interesting. I didn't lose focus and taught
          myself all the ins and outs of this language.
        </p>
        <p>
          In 2010, I started my career at&nbsp;
          <a
            href="https://www.gsd-ictservices.nl/"
            rel="noreferrer"
            target="_blank"
          >
            GSD-ICT Services
          </a>
          . First, I started as an ICT Support employee (Study). After
          completion, I decided to work for GSD as a Software Developer,
          <br />
          which I did for 7 years. I did a lot of cool things.
          <br />
          <br />
          In 2016, I started my employment at&nbsp;
          <a href="https://www.wellsky.nl" rel="noreferrer" target="_blank">
            WellSky Netherlands
          </a>
          as a .NET Software Developer to innovate their current product called
          CMS (Cytostatics Management System).
          <br />
          This software package is used to prescribe chemotherapy in hospitals
          around the world (Norway, Sweden, Netherlands).
          <br />I went to the UK on a regular basis to meet up with my UK
          colleagues and discuss our progress.
        </p>
        <p>
          Currently, I'm employed at&nbsp;
          <a href="https://www.gastonmedical.nl" rel="noreferrer" target="_blank">
            Gaston Medical
          </a>
          . Gaston Medical delivers eHealth solutions for providers,
          hospitals, laboratories, and more.
        </p>
        <div className="list-group mt-3 mb-3 myListGroup">
          <h2>Diplomas, Certificates, Achievements</h2>
          <span className="list-group-item list-group-item-action flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">Support Desk ICT (MBO)</h5>
              <small className="text-muted">(Education)</small>
            </div>
            <p className="mb-1">Arcus College</p>
          </span>
          <span className="list-group-item list-group-item-action flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">ICT (VMBO)</h5>
              <small className="text-muted">(Education)</small>
            </div>
            <p className="mb-1">Emma College</p>
          </span>
          <hr />
          <a
            rel="noreferrer"
            href="https://www.udemy.com/course/learn-to-build-an-e-commerce-app-with-net-core-and-angular"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">
                AngularJS - Learn to build an e-commerce app with .Net Core and
                Angular
              </h5>
              <small className="text-muted">2021 (Course)</small>
            </div>
            <p className="mb-1">By Udemy.com</p>
          </a>
          <a
            rel="noreferrer"
            href="https://www.udemy.com/course/react-the-complete-guide-incl-redux/"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">
                React - The Complete Guide (incl Hooks, React Router, Redux)
              </h5>
              <small className="text-muted">2019 - 2020 (Course)</small>
            </div>
            <p className="mb-1">By Udemy.com</p>
          </a>
          <a
            rel="noreferrer"
            href="https://www.pluralsight.com/courses/cross-platform-ios-android-visual-studio-csharp"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">
                Building Cross-Platform iOS/Android Apps with Xamarin
              </h5>
              <small className="text-muted">2016 (Certificate)</small>
            </div>
            <p className="mb-1">By PluralSight.com</p>
          </a>
          <a
            href="https://www.pluralsight.com/courses/csharp-interfaces"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">C# Interfaces</h5>
              <small className="text-muted">2016 (Certificate)</small>
            </div>
            <p className="mb-1">By PluralSight.com</p>
          </a>
          <a
            rel="noreferrer"
            href="https://www.pluralsight.com/courses/xamarin-forms-introduction"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">Introduction to Xamarin.Forms</h5>
              <small className="text-muted">2016 (Certificate)</small>
            </div>
            <p className="mb-1">By PluralSight.com</p>
          </a>
          <a
            rel="noreferrer"
            href="https://www.pluralsight.com/courses/jquery-fundamentals"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">jQuery Fundamentals</h5>
              <small className="text-muted">2016 (Certificate)</small>
            </div>
            <p className="mb-1">By PluralSight.com</p>
          </a>
        </div>
      </div>
    </DocumentMeta>
  );
};
