import SkillsJson from '../data/skills.json';

export const Skills = () => {
	function buildSkills() {
		return SkillsJson.map((element, index) => {
			var skills = element.skills
				.sort((a, b) => (a.category > b.category ? 1 : -1))
				.map((skill, sindex) => {
					return (
						<div key={sindex} className='list-group mt-3 mb-3 myListGroup'>
							<span className='list-group-item list-group-item-action flex-column align-items-start'>
								<div className='d-flex w-100 justify-content-between'>
									<h5 className='mb-1'>{skill.name}</h5>
									<small className='text-muted'>{skill.category}</small>
								</div>
								<small>{skill.description && <p>{skill.description}</p>}</small>
							</span>
						</div>
					);
				});
			return (
				<div key={index}>
					<h1>{element.company}</h1>
					{skills}
				</div>
			);
		});
	}
	return (
		<>
			<h1 className='animated pulse'>My Skills</h1>

			<p className='mb-0 pb-0'>I mention only the most important skills.</p>
			<p>Click a row for the link to the website.</p>

			{buildSkills()}
		</>
	);
};
