import "./css/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";

import { Signature } from "./pages/Signature";
import { AboutMe } from "./pages/AboutMe";
import { Skills } from "./pages/Skills";
import { Projects } from "./pages/Projects";
import { Contact } from "./pages/Contact";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import { DiscordBot } from "./components/DiscordBot";

function App() {
  return (
    <div className="container-fluid p-0 m-0">
      <Router>
        <NavBar />
        <div className="container">
          <div className="body-content animated fadeIn">
            <Switch>
              <Redirect exact from="/" to="/AboutMe" />
              <Route path="/AboutMe">
                <AboutMe />
              </Route>
              <Route path="/Skills">
                <Skills />
              </Route>
              <Route path="/Projects">
                <Projects />
              </Route>
              <Route path="/Contact">
                <Contact />
              </Route>
              <Route path="/DiscordBot">
                <DiscordBot />
              </Route>
            </Switch>
          </div>
          <Signature />
        </div>
      </Router>
    </div>
  );
}

export default App;
