import Navigation from "../data/navigation.json";
import LinkedinProfilePic from "../../src/images/stefan.jpeg";
import { Nav, Navbar } from "react-bootstrap";
import { withRouter } from "react-router";

const NavBar = () => {
  function buildMenu() {
    return Navigation.map((element, index) => {
      return (
        <div key={index} className="nav-item">
          <Nav.Link key={index} href={element.url}>
            {element.name}
          </Nav.Link>
        </div>
      );
    });
  }
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <div className="container">
        <Navbar.Brand className="navbar-brand" href="/">
          <img
            className="float-left rounded-me small-me img-fluid animated tada"
            src={LinkedinProfilePic}
            alt="linkedin profile"
          />
          <div className="float-left mt-3 ml-3">
            <p className="p-0 m-0 mb-2">{process.env.REACT_APP_NAME}</p>
            <p className="small p-0 m-0">
              {process.env.REACT_APP_FUNCTION}
              <br />
              at{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={process.env.REACT_APP_CURRENT_EMPLOYER_URL}
              >
                {process.env.REACT_APP_CURRENT_EMPLOYER}
              </a>
            </p>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={window.location.pathname} className="mr-auto ml-5">
            {buildMenu()}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default withRouter(NavBar);
